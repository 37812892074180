<template>
  <cs-segment class="cs-segment-print">
    <cs-container>
      <cs-row>
        <cs-col class="copyright text">
          <p>
            Copyright {{ $store.state.site.meta.copyright | replace('{year}', year) }} |
            {{ $store.state.site.contact.email.title }}
          </p>
        </cs-col>
      </cs-row>
    </cs-container>
  </cs-segment>
</template>

<script>
export default {
  name: 'cs-segment-print',
  computed: {
    year () {
      var d = new Date()
      return d.getFullYear()
    }
  }
}
</script>

<style lang="sass">
.cs-segment-print
  display: none
  margin-top: m(2)
  .copyright
    border-top: 1px solid black
    p
      padding-top: m(2)
      +typo('meta')

@media print
  .cs-segment-print
    display: block

</style>