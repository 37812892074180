<template>
  <div class="cs-sections">
    <template v-for="(section, i) in sections">

      <!-- Headlines, Copy -->
      <cs-row
        v-if="isText(section)"
        :key="key(i)"
        :class="sectionClass(section, i)"
        class="copy">
          <cs-text
            :data="section.text"
            :isSublevel="isSublevel"
            :class="cssClass"
            class="text"
            component="cs-col" />
      </cs-row>
      
      <!-- Footnote -->
      <cs-row
        v-if="isFootnote(section)"
        :key="key(i)"
        :class="sectionClass(section, i)"
        class="copy footnote">
          <cs-text
            :data="section.copy"
            :isSublevel="isSublevel"
            :class="cssClass"
            class="text"
            component="cs-col" />
      </cs-row>

      <!-- Images -->
      <cs-row
        v-if="isImages(section)"
        :key="key(i)"
        :class="sectionClass(section, i)"
        class="images">
          <cs-col
            :class="cssClass">
              <cs-images
                :data="section.images"
                :size="imagesSize(section)"
                :display="section.display"
                :zoom="section.zoom"
                :autoplay="section.autoplay"
                :ranges="section.ranges"
                :speed="section.speed"
                :oneRowXS="section.onerowmobile"
                :pagination="true"
                :title="imagesTitle(section)"
                :radius="imgRadius"
                paginationPosition="inside" />
          </cs-col>
      </cs-row>

      <!-- Image -->
      <cs-row
        v-if="isImage(section)"
        :key="key(i)"
        :class="sectionClass(section, i)"
        class="image">
          <cs-col
            :class="cssClass">
              <cs-image
                :data="section.image"
                :size="imageSize(section)"
                :radius="imgRadius" />
          </cs-col>
      </cs-row>

      <!-- Links -->
      <cs-row
        v-else-if="isLinks(section)"
        :key="key(i)"
        :class="sectionClass(section, i)"
        class="links"> 
          <cs-col
            :class="cssClass">
              <cs-links
                :data="section.links"
                :class="{
                  'is-list': section.display === 'list',
                  'is-buttons': section.display === 'buttons'
                }"
                :linkClass="linkClass(section)" />
          </cs-col>
      </cs-row>

      <!-- Quote -->
      <cs-row
        v-else-if="isQuote(section)"
        :key="key(i)"
        :class="sectionClass(section, i)"
        class="is-xs is-multiline-xs quote">
          <cs-col class="is-3 is-12-xs image">
            <cs-image
              :data="section.portrait"
              size="quote"
              radius="50%" />
          </cs-col>
          <cs-col class="is-9 is-10-xs is-offset-1-xs copy">
            <span class="icon-quote"></span>
            <cs-text
              :data="section.copy"
              class="typo" />
          </cs-col>
      </cs-row>

      <!-- Video -->
      <cs-row
        v-else-if="isVideo(section)"
        :key="key(i)"
        :class="sectionClass(section, i)"
        class="video">
          <cs-col
            :class="cssClass">
              <video-elem
                :source="section.source"
                :video="section.video" />
          </cs-col>
      </cs-row>
    </template>
  </div>
</template>

<script>
export default {
  name: 'cs-sections',
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    },

    // Subelements have h2 and h3 instead of h1 and h2
    isSublevel: {
      type: Boolean,
      default: false
    },

    // css-class for cs-col
    cssClass: {
      type: String,
      default: 'is-12'
    },

    // set radius of image like '10px' or '50%' (not for quote)
    imgRadius: {
      type: String,
      default: null
    }
  },
  computed: {
    sections () {
      var res = []
      var textKey = null
      fn.each(this.data, (section, key) => {
        
        // put h1 (h2) and copy together
        if (this.isHeadline(section) || this.isCopy(section)) {
          if (textKey !== null) {
            res[textKey].text.push(section)
          } else {
            res.push({
              fieldset: 'text',
              text: [ section ]
            })
            textKey = res.length - 1
          }
        }

        // transform links
        if (this.isLinks(section)) {
          var links = []
          fn.each(section.links, (entry) => {
            links.push(entry.link)
          })
          if (links.length > 0) {
            var newSection = fn.clone(section) // to keep all addtional fields like "display"
            newSection.links = links
            res.push(newSection)
            textKey = null
          }
        }

        // the rest
        else {
          res.push(section)
          textKey = null
        }
      })
      return res
    }
  },
  methods: {
    key (...keys) {
      return keys.join('-')
    },

    // original sections
    isHeadline (section) {
      return section.fieldset === 'headline'
    },
    isCopy (section) {
      return section.fieldset === 'copy'
    },
    isImage (section) {
      return section.fieldset === 'image'
    },
    isImages (section) {
      return section.fieldset === 'images' && this.hasImages(section)
    },
    isLinks (section) {
      return section.fieldset === 'links'
    },
    isQuote (section) {
      return section.fieldset === 'quote'
    },
    isVideo (section) {
      return section.fieldset === 'video'
    },
    isFootnote (section) {
      return section.fieldset === 'footnote'
    },

    // grouped sections
    isText (section) {
      return section.fieldset === 'text'
    },

    hasImages (section) {
      return fn.isArray(section.images) && section.images.length > 0
    },
    imageSize (section) {
      return 'sections.' + section.style
    },
    imagesSize (section) {
      return section.display === 'slider' ? 'sections.banner' : 'sections.row'
    },
    imagesTitle (section) {
      if (fn.has(section, 'subheadline') && fn.isString(section.subheadline)) {
        return section.subheadline
      }
      return ''
    },
    sectionClass (section, count) {
      var res = []
      res.push('section')
      if (count === 0) {
        res.push('section-first')
      }
      if (fn.has(section, 'cssclass') && fn.isString(section.cssclass)) {
        res.push(section.cssclass)
      }
      if (section.display === 'slider') {
        res.push('is-slider')
      }
      return res.join(' ')
    },
    linkClass (section) {
      if (section.display === 'buttons') {
        return 'button'
      }
    }
  }
}
</script>

<style lang="sass">
.cs-sections
  .section
    &.section-first
      .cs-col
        :first-child
          margin-top: 0
    .cs-col
      padding-top: 0
      padding-bottom: 0
    &.images
      margin-top: m(1)
      margin-bottom: m(3)
      &.is-slider
        .cs-col
          background-color: transparent
      .cs-box
        background-color: color('whiter')
    &.links
      margin-top: m(2)
      &:first-child
        margin-top: 0
      ul
        &.is-buttons
          display: flex
          justify-content: center
          flex-wrap: wrap
          li
            margin: m(1)
        &.is-list
          list-style: disc inside none
          margin-left: m(2)
    &.quote
      margin-top: m(2)
      .image
        display: flex
        flex-direction: column
        justify-content: center
        margin-bottom: m(4)
        .cs-image
          max-width: 80%
      .copy
        display: flex
        position: relative
        flex-direction: column
        justify-content: center
        margin-bottom: m(4)
        .icon-quote
          position: absolute
          top: - m(4)
          left: - m(4)
          font-size: 6rem
          line-height: 1
          color: color('lighter')
          z-index: 9
        .cs-text
          +typo('quote')
          z-index: 10
          > p, ul, li
            +typo('quote')
    &.footnote
      .cs-col
        margin-top: m(2)
        margin-bottom: m(4)
        padding-top: m(2)
        border-top: 1px solid color('primary')
        +typo('small')
        *
          +typo('small')

+xs
  .cs-sections
    .section
      &.quote
        .image
          display: flex
          flex-direction: row
          justify-content: center
          .cs-image
            max-width: 50%
  
</style>