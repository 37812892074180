<template>
  <component
    v-if="hasContent"
    :is="component"
    class="cs-text">
      <template v-for="(block, i) in paragraphs">
        <component
          :is="htmlBlock(block)"
          :key="i"
          :class="block.cssclass">
        </component>
      </template>
  </component>
</template>

<script>
export default {
  name: 'cs-text',
  props: {

  /**
   * Data may be
   * - Object (see below)
   * - a single copy field 
   * - a single string field
   * - other field types (not implemented yet)
   * - a string
   * - an array with all elements mixed
   */
    data: {
      default: ''
    },
    component: {
      type: String,
      default: 'div'
    },

    // Subelements have h2 and h3 instead of h1 and h2
    isSublevel: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasContent () {
      return (fn.isArray(this.data) && this.data.length > 0) ||
        (fn.isString(this.data) && this.data !== '')
    },

    // Convert given fields to flat array of html-blocks
    paragraphs () {
      var res = []
      var content = fn.isArray(this.data) ? this.data : [ this.data ]
      fn.each(content, (section) => {

        // simply a string
        if (fn.isString(section)) {
          res.push({
            tag: 'p',
            html: section
          })
          return
        }

        // a field
        if (fn.isObject(section) && fn.has(section, 'tag')) {
          res = res.concat(section)
          return
        }

        // sections
        // object with fields an additional data for css-classes
        // {
        //  headline: [field],
        //  subheadline: [field],
        //  copy: [field],
        //  cssclass: [field],
        //  style: [field]
        // }
        var cssClass = []
        if (fn.has(section, 'cssclass') && fn.isString(section.cssclass)) {
          cssClass.push(section.cssclass)
        }
        if (fn.has(section, 'headline') && fn.isString(section.headline)) {
          res.push({
            tag: this.isSublevel? 'h2': 'h1',
            html: section.headline,
            cssclass: cssClass.join(' ')
          })
        }
        
        if (fn.has(section, 'subheadline') && fn.isString(section.subheadline)) {
          res.push({
            tag: this.isSublevel? 'h3': 'h2',
            html: section.subheadline,
            cssclass: cssClass.join(' ')
          })
        }

        if (fn.has(section, 'copy')) {
          if (fn.has(section, 'style')) {
            cssClass.push('is-' + section.style)
          }
          if (fn.isArray(section.copy)) {
            fn.each(section.copy, (block) => {
              block.cssclass = cssClass.join(' ')
              res.push(block)
            })
          }
        }
      })
      return res
    }
  },
  methods: {
     htmlBlock (block) {
      if (!fn.has(block, 'html')) {
        return {
          template: '<' + block.tag + ' />'
        }
      }
      let regex = /^<code>.*<\/code>$/i
      if (fn.isString(block.html) && regex.test(block.html)) {
        return { template: '<pre>' + block.html + '</pre>' }
      }
      return {
        template: '<' + block.tag + '>' + block.html + '</' + block.tag + '>'
      }
    }
  }
}
</script>

<style lang="sass">
// .cs-text
</style>