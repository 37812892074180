<template>
  <cs-segment
    v-if="isVisible"
    :class="{
      'is-visible': isVisible,
      'is-fullscreen': isFullscreen,
      'is-dialog': !isFullscreen,
      'is-error': isError
    }"
    class="cs-segment-modal">
      <div class="viewbox">
        <div v-if="!isModal" class="close">
          <cs-button class="is-icon is-narrow" icon="close"  @click="cancelHandler" />
        </div>
        <div class="body text">
          <component :is="bodyComponent" :content="bodyContent" />
        </div>
        <div
          v-if="hasSpinner"
          :class="{ 'has-actions': hasActions }"
          class="spinner">
            <cs-spinner :delay="false" />
        </div>
        <div v-if="hasActions" class="actions">
          <cs-button
            v-if="hasActionCancel"
            @click="cancelHandler"
            class="is-text">
              {{ params.actions.cancel.label || 'Cancel' }}
          </cs-button>
          <cs-button
            v-if="hasActionOther"
            @click="otherHandler"
            class="is-text">
              {{ params.actions.other.label || 'undefined' }}
          </cs-button>
          <cs-button
            v-if="hasActionSubmit"
            @click="submitHandler"
            class="is-tool is-primary">
              {{ params.actions.submit.label || 'Submit' }}
          </cs-button>
        </div>
      </div>
      <cs-overlay :visible="isVisible" />
  </cs-segment>
</template>

<script>
import csOverlay from '@/components/elements/Overlay'

export default {
  name: 'cs-segment-modal',
  components: {
    csOverlay
  },
  data () {
    return {
      /**
       * {
       *   text: 'The message text',
       *   sections: alternative to text
       *   fullscreen: bool, // fullscreen display, for longer texts
       *   modal: bool, // no close button, no click on overlay,
       *   spinner: bool, // show spinner
       *   error: bool // error styling
       *   actions: {
       *     submit: {
       *       label: 'Button label',
       *       event: optional,
       *       params: optional event params
       *     },
       *     other: {
       *       label: 'Button label',
       *       event: optional,
       *       params: optional event params
       *     },,
       *     cancel: {
       *       label: 'Button label',
       *       event: optional,
       *       params: optional event params
       *     }
       *   }
       * }
       */
      params: {},
      text: null,
      isVisible: false
    }
  },
  computed: {
    isModal () {
      return fn.has(this.params, 'modal') && fn.isTrue(this.params.modal)
    },
    isFullscreen () {
      return fn.has(this.params, 'fullscreen') && fn.isTrue(this.params.fullscreen)
    },
    isError () {
      return fn.has(this.params, 'error') && fn.isTrue(this.params.error)
    },
    hasSpinner () {
      return fn.has(this.params, 'spinner') && fn.isTrue(this.params.spinner)
    },
    hasActions () {
      return fn.isObject(this.params.actions)
    },
    hasActionCancel () {
      return this.hasActions && fn.isObject(this.params.actions.cancel)
    },
    hasActionOther () {
      return this.hasActions && fn.isObject(this.params.actions.other)
    },
    hasActionSubmit () {
      return this.hasActions && fn.isObject(this.params.actions.submit)
    },
    bodyComponent () {
      return fn.has(this.params, 'sections') ? 'cs-sections' : 'cs-text'
    },
    bodyContent () {
      return fn.has(this.params, 'sections') ? this.params.sections : this.params.text
    }
  },
  methods: {
    showHandler (Event) {
      this.params = Event.detail
      this.isVisible = true
      document.activeElement.blur()
    },
    hideHandler (Event) {
      this.isVisible = false
      this.params = {}
    },
    cancelHandler (Event) {
      if (this.isVisible) {
        if (this.hasActionCancel && fn.isString(this.params.actions.cancel.event)) {
          this.$trigger(this.params.actions.cancel.event, (this.params.actions.cancel.params || {}))
        }
        this.hideHandler()
      }
    },
    otherHandler () {
      if (this.isVisible) {
        if (this.hasActionOther && fn.isString(this.params.actions.other.event)) {
          this.$trigger(this.params.actions.other.event, (this.params.actions.other.params || {}))
        }
        this.hideHandler()
      }
    },
    submitHandler (Event) {
      if (this.isVisible) {
        if (this.hasActionSubmit && fn.isString(this.params.actions.submit.event)) {
          this.$trigger(this.params.actions.submit.event, (this.params.actions.submit.params || {}))
        }
        this.hideHandler()
      }
    }
  },
  events: {
    'modal/show': 'showHandler',
    'modal/hide': 'hideHandler',
    'key/esc': 'cancelHandler',
    'key/enter': 'submitHandler'
  }
}
</script>

<style lang="sass">
$dialog-width: 620px
.cs-segment-modal
  position: fixed
  display: none
  flex-direction: column
  top: 0
  left: 0
  right: 0
  bottom: 0
  +z-index('modal')
  .viewbox
    +z-index('modal', 2)
  &.is-dialog
    justify-content: center
    .viewbox
      display: flex
      flex-direction: column
      flex-shrink: 1
      max-width: $dialog-width
      margin: 0 auto
      background-color: color('white')
      .close
        position: relative
        display: flex
        justify-content: flex-end
        line-height: 0
        .close-btn
          position: absolute
          transform: translate(10px, -10px)
      .body
        padding: m(2)
      .spinner
        line-height: 0
        padding-bottom: m(3)
        &.has-actions
          padding-bottom: m(1)
      .actions
        display: flex
        justify-content: center
        padding-bottom: m(2)
        .cs-button
          margin: 0 m(1)
  &.is-fullscreen
    justify-content: flex-start
    .viewbox
      position: relative
      width: content-width()
      max-width: 100%
      height: 100%
      max-height: 100%
      margin: 0 auto
      background-color: color('white')
      overflow-y: scroll
      -webkit-overflow-scrolling: touch
      .close
        position: relative
        display: flex
        justify-content: flex-end
        line-height: 0
        padding: m(2) m(2) 0 0
        .close-btn
          position: fixed
      .body
        display: flex
        flex-direction: column
        align-items: center
        padding: m(4) content-padding('md')
      .spinner
        padding: m(4) content-padding('md')
        line-height: 0
        padding-bottom: m(3)
        &.has-actions
          padding-bottom: m(1)
      .actions
        display: flex
        justify-content: center
        padding: m(4) content-padding('md')
        .cs-button
          margin: 0 m(1)
  &.is-error
    .viewbox
      border-top: 10px solid color('warning')
      .close
        .close-btn
          transform: translate(10px, -24px)
  &.is-visible
    display: flex
  .cs-overlay
    +z-index('modal', 1)

+md
  .cs-segment-modal
    &.is-fullscreen
      .viewbox
        width: content-width('md')

+sm
  .cs-segment-modal
    &.is-fullscreen
      .viewbox
        width: content-width('sm')
        .body,
        .actions
          padding: m(4) content-padding('sm')

+xs
  .cs-segment-modal
    &.is-fullscreen
      .viewbox
        width: content-width('xs')
        .body,
        .actions
          padding: m(4) content-padding('xs')

+until($dialog-width)
  .cs-segment-modal
    &.is-dialog
      justify-content: flex-start
      background-color: white
      .viewbox
        .close
          padding: m(2) m(2) 0 0
          .close-btn
            position: relative
            transform: none
</style>
