<template>
  <div
    class="cs-images"
    :class="{
      'has-navigation': hasNavigation,
      'has-pagination': hasPagination && !hasInfo,
      'is-pagination-inside': isPaginationInside,
      'has-zoom': hasZoom
    }">
      <!-- Carousel for slider or row -->
      <div class="images-carousel" v-if="isDisplayAll === false">
        <carousel
          :perPage="perPageXS"
          :perPageCustom="perPageBreakpoints"
          :navigateTo="currentPage"
          :navigationEnabled="false"
          :paginationEnabled="false"
          :autoplay="hasAutoplay"
          :autoplayHoverPause="hasZoom"
          :autoplayTimeout="autoplay"
          :speed="speed"
          :loop="hasLoop"
          :centerMode="true"
          :mouseDrag="false"
          navigationPrevLabel=""
          navigationNextLabel=""
          @page-change="pageChangeHandler">
            <slide
              v-for="(file, i) in data"
              v-bind:key="i"
              @click.native="clickImageHandler($event, i)"
              class="slide">
                <div class="frame">
                  <cs-image
                    :data="file"
                    :size="size"
                    :radius="radius">
                  </cs-image>
                </div>
            </slide>
        </carousel>
        <div class="navigation prev">
          <cs-button
            icon="left"
            class="is-big is-icon is-white"
            :class="{ 'is-disabled': !hasPrevPage }"
            @click="prevPageHandler()">
          </cs-button>
        </div>
        <div class="navigation next">
          <cs-button
            icon="right"
            class="is-big is-icon is-white"
            :class="{ 'is-disabled': !hasNextPage }"
            @click="nextPageHandler()">
          </cs-button>
        </div>
      </div>

      <!-- Show all images, no Carousel -->
      <cs-row
        v-if="isDisplayAll"
        class="images-all is-xs is-multiline"
        :class="{ 'is-centered': isIncompleteRow }"
        >
        <cs-col
          v-for="(file, i) in data"
          v-bind:key="i"
          @click.native="clickImageHandler($event, i)"
          :class="[ colClass ]"
          class="slide">
            <div class="frame">
              <cs-image
                :data="file"
                :size="size"
                :radius="radius">
              </cs-image>
            </div>
        </cs-col>
      </cs-row>

      <!-- Pagination -->
      <div
        v-if="isDisplayAll === false"
        class="images-pagination"
        ref="paginationWrapper">
          <div class="pagination" ref="pagination">
            <div
              class="dot"
              v-for="i in pagecount"
              :key="i"
              :class="{ 'is-active': currentPage === (i - 1) }"
              @click="clickGotoHandler(i - 1)">
            </div>
          </div>
      </div>

      <!-- Legend -->
      <div
        v-if="hasLegend"
        class="images-legend">
          <p
            v-if="hasTitle"
            class="legend">
              {{ title }}
          </p>
          <cs-button
            v-if="hasInfo"
            @click="clickImageHandler($event, 0)"
            icon="view"
            class="button is-text move-down pull-right move-right">
              {{ data.length }} Bilder
          </cs-button>
      </div>
  </div>
</template>

<script>
import { breakpoints } from '@/config/style.json'
import { Carousel, Slide } from 'vue-carousel'

export default {
  name: 'cs-images',
  components: {
    Carousel,
    Slide
  },
  props: {

    // The list of images
    data: {
      type: Array
    },

    // size, path from images.json like 'sections.banner'
    size: {
      type: String,
      default: 'default'
    },

    // all, row, slider
    display: {
      type: String,
      default: 'all'
    },

    // enable/disable fullsceen zoom
    zoom: {
      type: Boolean,
      default: true
    },

    // msec for autoplay, 0 = off
    autoplay: {
      type: Number,
      default: 0
    },

    // msec for transition
    speed: {
      type: Number,
      default: 500
    },

    // number of images per row and breakpoint
    ranges: {
      type: String,
      default: '5,4,3,2'
    },

    // enable/disable the navigation (back and forward arrows)
    // values: true, false, a list of breakpoints ['xs', 'sm'] or
    // a single breakpoint 'xs'
    navigation: {
      default: true
    },

    //enable/disable the pagination (dots)
    //values: true, false, a list of breakpoints ['xs', 'sm'] or
    //a single breakpoint 'xs'
    pagination: {
      default: true
    },

    // when true, display = all will not be active on xs devices
    oneRowXS: {
      type: Boolean,
      default: false
    },

    // show gallery info (Count of images)
    info: {
      type: Boolean,
      default: false
    },

    // gallery title
    title: {
      type: String,
      default: ''
    },

    radius: {
      type: String,
      default: null
    },

    // outside, inside, only for slider
    paginationPosition: {
      type: String,
      default: 'outside'
    }
  },
  data () {
    return {
      breakpoint: null,
      abscount: 0,
      pagecount: 0,
      rangecount: 0,
      currentPage: 0,
      rangesBreakpoints: {},
      navigationBreakpoints: {},
      paginationBreakpoints: {},
      paginationFit: false,
      currentImageIndex: 0
    }
  },
  created () {
    this.rangesBreakpoints = this.getRangesBreakpoints()
    this.navigationBreakpoints = this.getBreakpoints(this.navigation)
    this.paginationBreakpoints = this.getBreakpoints(this.pagination)
    this.initBreakpoint(this.$dom.getBreakpoint())
  },
  mounted () {
    this.initPagination()
  },
  computed: {
    perPageXS () {
      return this.isDisplaySlider ? 1 : this.rangesBreakpoints.xs
    },
    perPageBreakpoints () {

      // breakpoint-logic in vueCarousel is sm-first, where
      // as sass logic is lg-first. So the breakpoint-
      // values have to specifiy the min of each range.
      var res = [[
        breakpoints.xs + 1,
        this.isDisplaySlider ? 1 : this.rangesBreakpoints.sm
      ],[
        breakpoints.sm + 1,
        this.isDisplaySlider ? 1 : this.rangesBreakpoints.md
      ],[
        breakpoints.md + 1,
        this.isDisplaySlider ? 1 : this.rangesBreakpoints.lg
      ]]
      return res
    },
    hasAutoplay () {
      return !this.isDisplayAll && fn.isInteger(this.autoplay, 1) && this.pagecount > 1 && !this.isZoomed ? true : false
    },
    hasLoop () {
      return !this.isDisplayAll
    },
    isDisplaySlider () {
      return this.display === 'slider'
    },
    isDisplayAll () {
      if (this.isOneRowXS) {
        return false
      }
      return this.display === 'all'
    },
    isDisplayRow () {
      if (this.isOneRowXS) {
        return true
      }
      return this.display === 'row'
    },
    isIncompleteRow () {
      return this.abscount < this.currentRowCount
    },
    isOneRowXS () {
      return this.display === 'all' && this.oneRowXS && this.isXS
    },
    isXS () {
      return this.breakpoint === 'xs'
    },
    isPaginationInside () {
      return this.paginationPosition === 'inside' && this.isDisplaySlider
    },
    hasPrevPage () {
      return this.currentPage > 0
    },
    hasNextPage () {
      return this.currentPage < (this.pagecount - 1)
    },
    hasNavigation () {
      return this.navigationBreakpoints[this.breakpoint] && this.pagecount > 1
    },
    hasPagination () {
      return this.paginationBreakpoints[this.breakpoint] && this.pagecount > 1 && this.paginationFit
    },
    hasInfo () {
      return this.info || (this.hasTitle && !this.isPaginationInside)
    },
    hasLegend () {
      return  this.hasTitle || this.hasInfo
    },
    hasTitle () {
      return fn.isString(this.title)
    },
    hasZoom () {
      return this.zoom
    },
    currentRowCount () {
      return this.rangesBreakpoints[this.breakpoint]
    },
    colClass () {
      return 'is-1-from-' + this.currentRowCount
    }
  },
  methods: {
    initPagination () {
      if (this.$refs.pagination !== undefined) {
        this.paginationFit = this.$refs.pagination.clientWidth <= this.$refs.paginationWrapper.clientWidth
      }
    },
    initBreakpoint (breakpoint) {
      this.breakpoint = breakpoint
      this.abscount = this.data.length
      this.rangecount = this.isDisplaySlider ? 1 : this.rangesBreakpoints[this.breakpoint]
      this.pagecount = fn.round(this.abscount / this.rangecount, 0, 'up')
    },

    /**
     * converts string like 5,4,3,2 to map
     */
    getRangesBreakpoints () {
      var ranges = this.ranges.split(',')
      if (ranges[0] ===  undefined) {
        ranges = [5,4,3,2]
      }
      var res = {}
      var i = 0
      var range = null
      fn.each(breakpoints, (value, breakpoint) => {
        if (ranges[i] !== undefined) {
          range = fn.toInteger(ranges[i])
        }
        res[breakpoint] = range
        i++
      })
      return res
    },

    /**
     * for props hasPagination and hasNavigation
     * build a lookup-map for each breakpoint that 
     * indicates, if pagination/navigation is visible.
     * @param {mixed} val, true, a single breakpoint or a list of breakpoints
     */
    getBreakpoints (val) {
      var res = {}
      fn.each(breakpoints, (foo, name) => {
        if (
          fn.isTrue(val) ||
          (fn.isString(val) && val === name) ||
          (fn.isArray(val) && fn.inArray(name, val))
        ) {
          res[name] = true
        } else {
          res[name] = false
        }
      })
      return res
    },
    clickImageHandler(Event, i) {
      if (Event) {
        Event.stopPropagation()
      }
      this.currentImageIndex = i
      if (this.hasZoom) {
        this.$trigger('slideshow/open', {
          files: this.data,
          start: this.currentImageIndex
        })
      }
    },
    pageChangeHandler (pagenum) {
      if (pagenum !== this.currentPage) {
        this.currentPage = pagenum // needed for autoplay
      }
    },
    clickGotoHandler(pageNum) {
      if (fn.isInteger(pageNum, 0, this.pagecount)) {
        this.currentPage = pageNum
      }
    },
    prevPageHandler () {
      if (this.hasPrevPage) {
        this.currentPage--
      }
    },
    nextPageHandler () {
      if (this.hasNextPage) {
        this.currentPage++
      }
    },
    breakpointHandler (Event) {
      this.initBreakpoint(Event.detail)
    },
    windowWidthHandler (Event) {
      this.initPagination()
    }
  },
  events: {
    'window/breakpoint': 'breakpointHandler',
    'window/width': 'windowWidthHandler'
  }
}
</script>

<style lang="sass">
$dot-color: color('lighter')
$dot-active-color: color('primary')
$dot-width: 14px
$dot-height: 14px

.cs-images
  position: relative
  display: block
  overflow: hidden
  margin-top: - (col-gap()/2)
  margin-bottom: - (col-gap()/2)
  .slide
    display: flex
    flex-direction: column
    position: relative
    padding: (col-gap()/2) !important
    justify-content: center
    align-items: center
    line-height: 0
    // .frame
    .caption
      padding: .3rem 0
      line-height: line-height('regular')
  .images-carousel // unique name, otherwise cs-image-zoom will inherit
    position: relative
    .VueCarousel
      margin: 0
      padding: 0
      .VueCarousel-wrapper
        .VueCarousel-inner
          margin-left: -(col-gap() / 2)
          margin-right: -(col-gap() / 2)
    .navigation
      display: none
      position: absolute
      top: col-gap()/2
      bottom: col-gap()/2 // col-gap() + (1.5 * $dot-height)
      width: m(5)
      .cs-button
        height: 100%
        width: 100%
        &.is-disabled
          //display: none
      &.prev
        left: 0
        .cs-button
          justify-content: flex-start
      &.next
        right: 0
        .cs-button
          justify-content: flex-end
  .images-pagination
    position: relative
    text-align: center
    line-height: 0
    font-size: 0
    overflow: hidden
    .pagination
      position: absolute
      top: -9999px // invisible, but in DOM, don't change, set to 0 if visible
      padding-top: $dot-height / 2 // pixel required, @see navigation bottom
      padding-bottom: m(1)
      display: inline-flex
      .dot
        flex-shrink: 0
        margin: 0 3px
        width: $dot-width
        height: $dot-height
        background-color: $dot-color
        cursor: pointer
        border-radius: 7px
        &:first-child
          margin-left: 0
        &:last-child
          margin-right: 0
        &:not(.is-active):hover
          background-color: darken($dot-color, 20%)
        &.is-active
          background-color: $dot-active-color
          cursor: default
  .images-legend
    display: flex
    justify-content: space-between
    align-items: center
  &.has-navigation
    .images-carousel
      .navigation
        display: block
  &.has-pagination
    .images-pagination
      .pagination
        position: relative
        top: 0
  &.is-pagination-inside
    .images-carousel
      .navigation
        bottom: col-gap()/2
    .images-pagination
      width: 100%
      transform: translateY(- m(5))
      margin-bottom: - $dot-height
      .pagination
        padding: 0 m(1)
        .dot
          background-color: color('white')
          opacity: .6
          &:not(.is-active):hover
            background-color: color('white')
            opacity: 1
          &.is-active
            background-color: $dot-active-color
            opacity: 1
  &.has-zoom
    .slide
      .frame
        position: relative
        cursor: pointer
        overflow: hidden
        &:after
          display: flex
          justify-content: center
          align-items: center
          content: map-get($icons, 'fullscreen')
          position: absolute
          left: 0
          top: 0
          width: 100%
          height: 100%
          font-family: font-family('icons')
          font-size: 1.5rem
          color: color('white')
          background-color: color('black')
          opacity: 0
          transition: opacity .6s ease-out .4s
        picture
          img
            transition: all .6s ease-out .4s
        &:hover
          &:after
            opacity: .4
          picture
            img
              transform: scale(1.1)

+md
  .cs-images
    margin-top: - (col-gap('md')/2)
    margin-bottom: - (col-gap('md')/2)
    .slide
      padding: (col-gap('md')/2) !important
    .images-carousel
      .VueCarousel
        .VueCarousel-inner
          margin-left: -(col-gap('md') / 2)
          margin-right: -(col-gap('md') / 2)
      .navigation
        top: col-gap('md')/2
        bottom: col-gap('md')/2 // col-gap('md') + (1.5 * $dot-height)
    &.is-pagination-inside
      .images-carousel
        .navigation
          bottom: col-gap('md')/2

+sm
  .cs-images
    margin-top: - (col-gap('sm')/2)
    margin-bottom: - (col-gap('sm')/2)
    .slide
      padding: (col-gap('sm')/2) !important
    .images-carousel
      .VueCarousel
        .VueCarousel-inner
          margin-left: -(col-gap('sm') / 2)
          margin-right: -(col-gap('sm') / 2)
      .navigation
        top: col-gap('sm')/2
        bottom: col-gap('sm')/2 // col-gap('sm') + (1.5 * $dot-height)
    &.is-pagination-inside
      .images-carousel
        .navigation
          bottom: col-gap('sm')/2

+xs
  .cs-images
    margin-top: - (col-gap('xs')/2)
    margin-bottom: - (col-gap('xs')/2)
    .slide
      padding: (col-gap('xs')/2) !important
    .images-carousel
      .VueCarousel
        .VueCarousel-inner
          margin-left: -(col-gap('xs') / 2)
          margin-right: -(col-gap('xs') / 2)
      .navigation
        top: col-gap('xs')/2
        bottom: col-gap('xs')/2 // col-gap('xs') + (1.5 * $dot-height)
    .images-legend
      flex-direction: column
    &.is-pagination-inside
      .images-carousel
        .navigation
          bottom: col-gap('xs')/2
</style>