<template>
  <div
    @click="$emit('click', $event)"
    :class="{ 'is-visible': visible }"
    class="cs-overlay">
      <cs-spinner v-if="spinner" class="is-white" />
  </div>
</template>

<script>
export default {
  name: 'cs-overlay',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    spinner: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="sass">
.cs-overlay
  position: fixed
  visibility: hidden
  top: 0
  left: 0
  right: 0
  bottom: 0
  opacity: 0
  background-color: color('black')
  transition: opacity .5s ease
  +z-index('modal', -1)
  // define z-index in parent
  &.is-visible
    visibility: visible
    opacity: .7
    .cs-spinner
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
</style>
