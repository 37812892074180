<template>
  <picture
    v-if="isLoaded"
    @click="clickHandler($event)"
    @mouseover="mouseOverHandler($event)"
    @mouseleave="mouseLeaveHandler($event)"
    :class="{ 'is-hero': hero }"
    class="cs-image">
      <source v-if="has(curSize, 'xs')" :srcset="getSrcset(curFile, curSize, 'xs')" :media="getMedia('xs')" />
      <source v-if="has(curSize, 'sm')" :srcset="getSrcset(curFile, curSize, 'sm')" :media="getMedia('sm')" />
      <source v-if="has(curSize, 'md')" :srcset="getSrcset(curFile, curSize, 'md')" :media="getMedia('md')" />
      <img
        :srcset="getSrcset(curFile, curSize, 'lg')"
        :alt="alt"
        :style="imgStyle" />
  </picture>
</template>

<script>
import imagesConfig from '@/config/images.json'
import { breakpoints } from '@/config/style.json'

export default {
  name: 'cs-image',
  props: {

    // the image object
    data: {
      type: Object
    },

    // optional
    dataHover: {
      type: Object
    },

    // mixed - either a node from images.json or
    // the array with the scaling config
    size: {},

    // optional
    sizeHover: {},

    // hero / fullsize
    // parent must have required height!
    hero: {
      type: Boolean,
      default: false
    },

    radius: {
      type: String,
      default: null
    },

    deferred: null
  },
  data () {
    return {
      breakpoint: null,
      isHovered: false,
      isLoaded: false // only false on first load
    }
  },
  created () {
    this.breakpoint = this.$dom.getBreakpoint()
    this.preloadHandler()
  },
  computed: {
    alt () {
      var alt = fn.path(this.data, 'content.alt')
      return fn.escape(alt)
    },
    imgStyle () {
      return fn.isString(this.radius) ? 'border-radius:' + this.radius : ''
    },
    curFile () {
      return (this.dataHover && this.isHovered) ? this.dataHover : this.data
    },
    curSize () {
      return (this.sizeHover && this.isHovered) ? this.sizeHover : this.size
    }
  },
  methods: {
    has (size, breakpoint) {
      if(fn.isString(size)) {
        size = fn.path(imagesConfig, size)
      }
      var res = fn.isObject(size) ? fn.has(size, breakpoint) : fn.isArray(size) && breakpoint === 'lg'
      return res
    },
    getSrcset (file, size, breakpoint) {
      var image = this.$image.get(
        file,
        this.getConfig(size, breakpoint),
        'auto'
      )
      return fn.isObject(image) ? image.src : ''
    },
    getMedia (breakpoint) {
      return '(max-width: ' + breakpoints[breakpoint] + 'px)'
    },
    getConfig (size, breakpoint) {
      var res = size
      if(fn.isString(res)) {
        res += '.' + breakpoint
      }
      return res
    },

    /**
     * preload a single image
     * because image config (=size) may not have entries for every
     * breakpoint, breakpoints as a list from small to big are given
     * to detect the best config
     * @return {Promise}
     */
    preloadImage (file, size, breakpoints) {
      for(var i = 0; i < breakpoints.length; i++) {
        if (this.has(size, breakpoints[i])) {
          return this.$image.preload(
            file,
            this.getConfig(size, breakpoints[i]),
            'auto'
          )
        }
      }
      return new Promise((resolve) => {
        resolve()
      })
    },
    preloadHandler () {
      var promises = []

      // get all breakpoints from current to bigger
      var breakpoints = ['xs', 'sm', 'md', 'lg']
      breakpoints = breakpoints.slice(breakpoints.indexOf(this.breakpoint))

      // preload regular image
      promises.push(this.preloadImage(this.data, this.size, breakpoints))

      // preload hover image, if any
      if (this.dataHover || this.sizeHover) {
        promises.push(this.preloadImage(
          this.dataHover || this.data,
          this.sizeHover || this.size,
          breakpoints
        ))
      }
      Promise.all(promises)
        .finally(() => {
          this.isLoaded = true
          if (this.deferred) {
            this.deferred.resolve()
          }
          this.$emit('load', new CustomEvent('load', {
            bubbles: true,
            cancelable: true,
            detail: false
          }))
        })
    },
    clickHandler (Event) {
      this.$emit('click', Event)
    },
    mouseOverHandler (Event) {
      Event.stopPropagation()
      this.isHovered = true
      this.$emit('mouseover', Event)
    },
    mouseLeaveHandler(Event) {
      Event.stopPropagation()
      this.isHovered = false
      this.$emit('mouseleave', Event)
    },
    windowBreakpointHandler (Event) {
      if (Event.detail !== this.breakpoint) {
        this.breakpoint = Event.detail
        this.preloadHandler()
      }
    }
  },
  events: {
    'window/breakpoint': 'windowBreakpointHandler'
  }
}
</script>

<style lang="sass">
.cs-image
  // no display: flex !!
  font-size: 0
  line-height: 0
  &.is-hero
    display: flex
    flex-direction: row
    width: 100%
    height: 100%
    overflow: hidden
    img
      flex-grow: 1
      font-family: "object-fit:cover;object-position:50% 50%"
      object-fit: cover
      object-position: 50% 50%
      width: 100%
      height: auto
</style>