<template>
  <cs-segment
    v-if="show"
    :class="{ 'is-visible': isVisible }"
    class="cs-segment-slideshow">
      <div
        :class="{ 'is-single': abscount === 1 }"
        class="canvas">
          <div class="menu">
            <transition name="fade" mode="out-in">
              <p :key="currentImage" class="caption typo is-soft">
                {{ caption }}
              </p>
            </transition>
            <cs-button
              icon="close"
              class="is-big is-icon is-narrow is-white"
              @click="closeHandler">
            </cs-button>
          </div>
          <div class="image">
            <transition :name="transitionName" mode="out-in">
              <div
                class="wrapper"
                :key="image.filename"
                v-touch:swipe.left="gotoNextImageHandler"
                v-touch:swipe.right="gotoPrevImageHandler">
                  <cs-image
                    :data="image"
                    @load="imageLoadHandler()"
                    size="zoom.fullsize">
                  </cs-image>
              </div>
            </transition>
            <cs-spinner
              v-if="!isLoaded"
              :delay="true"
              class="is-white" />
            <div class="nav-image prev">
              <cs-button
                icon="left"
                class="is-big is-icon is-white"
                :class="{ 'is-disabled': !hasPrevImage }"
                @click="gotoPrevImageHandler()">
              </cs-button>
            </div>
            <div class="nav-image next">
              <cs-button
                icon="right"
                class="is-big is-icon is-white"
                :class="{ 'is-disabled' : !hasNextImage }"
                @click="gotoNextImageHandler()">
              </cs-button>
            </div>
          </div>
          <div class="thumbs" ref="thumbsContainer">
            <div
              v-if="hasSectionNavigation"
              class="nav-thumbs prev">
                <cs-button
                  icon="left"
                  class="is-icon is-narrow is-white"
                  :class="{ 'is-disabled' : !hasPrevPage }"
                  @click="gotoPrevPageHandler()">
                </cs-button>
            </div>
            <carousel
              :perPage="rangecount"
              :paginationEnabled="false"
              :navigationEnabled="true"
              :centerMode="true"
              :navigateTo="currentPage"
              :mouseDrag="false"
              :style="carouselStyle"
              class="sideshow-elem-carousel">
                <slide
                  v-for="(file, i) in files"
                  :key="i"
                  :class="{ 'is-current': (i === currentImage) }">
                    <div
                      class="frame"
                      :style="thumbFrameStyle"
                      @click="clickThumbHandler($event, i)">
                        <cs-image
                          :data="file"
                          :size="thumbConfig">
                        </cs-image>
                        <div class="marker is-softer"></div>
                    </div>
                </slide>
            </carousel>
            <div
              v-if="hasSectionNavigation"
              class="nav-thumbs next">
                <cs-button
                  icon="right"
                  class="is-icon is-narrow is-white"
                  :class="{ 'is-disabled' : !hasNextPage }"
                  @click="gotoNextPageHandler()">
                </cs-button>
            </div>
          </div>
      </div>
  </cs-segment>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'

export default {
  name: 'cs-segment-slideshow',
  components: {
    Carousel,
    Slide
  },
  data () {
    return {
      show: false, // for if-statement
      isVisible: false, // for animation
      files: [],
      currentImage: -1,
      abscount: null,
      rangecount: null,
      pagecount: null,
      currentPage: 0,
      transitionName: 'slide-left',
      thumbSize: 64,
      thumbPadding: 1,
      lastWindowWidth: 0,
      isLoaded: false
    }
  },
  computed: {
    image () {
      return this.files[this.currentImage]
    },
    hasPrevImage () {
      return this.currentImage > 0
    },
    hasNextImage () {
      return (this.currentImage + 1) < this.abscount
    },
    hasSectionNavigation () {
      return this.abscount > this.rangecount
    },
    hasPrevPage () {
      return this.currentPage > 0
    },
    hasNextPage () {
      return this.currentPage < Math.floor((this.abscount - 1) / this.rangecount)
    },
    thumbConfig () {
      return [ this.thumbSize, this.thumbSize, true ]
    },
    caption () {
      return ''
      //if (fn.has(this.files[this.currentImage].content, 'title') && fn.isString(this.files[this.currentImage].content.title)) {
      //  return this.files[this.currentImage].content.title
      //} else {
      //  return ''
      //}
    },
    carouselStyle () {
      let frame = this.thumbSize + (this.thumbPadding * 2)
      return 'width:' + frame * this.rangecount + 'px'
    },
    thumbFrameStyle () {
      return 'width:' + this.thumbSize + 'px;' +
        'padding-right:' + this.thumbPadding + 'px;' +
        'padding-left:' + this.thumbPadding + 'px;'
    }
  },
  methods: {
    init () {
      this.abscount = this.files.length
      if (this.$refs.thumbsContainer === undefined) {
        return
      }
      let frame = this.thumbSize + (this.thumbPadding * 2)
      let space = this.$refs.thumbsContainer.clientWidth -
        (2 * frame) // extra space for buttons and padding
      let thumbs = fn.round(space / frame, 0, 'down')
      if (thumbs > this.abscount) {
        thumbs = this.abscount
      }
      this.rangecount = (thumbs < 1) ? 1 : thumbs
      this.pagecount = fn.round(this.abscount / this.rangecount, 0, 'up')
    },
    open (files, start) {
      if (!fn.isInteger(start, 0)) {
        start = 0
      }
      if (fn.isArray(files) && files[start] !== undefined) {
        this.files = files
        this.show = true
        this.selectImage(start)
        this.$nextTick(() => {
          this.init()
          this.gotoCurrentPage()
          this.isVisible = true
        })
      }
    },
    close () {
      var currentImage = this.currentImage
      this.isVisible = false
      window.setTimeout(() => {
        this.show = false
        this.currentImage = -1
        this.files = []
        this.$trigger('slideshow/closed', currentImage)
      }, 600) // = transition-duration
    },
    selectImage (start, updatePage) {
      this.isLoaded = false
      this.transitionName = this.currentImage < start ? 'slide-left' : 'slide-right'
      this.currentImage = start
      if (updatePage) {
        this.gotoCurrentPage()
      }
    },
    gotoCurrentPage () {
      let page = fn.round(this.currentImage / this.rangecount, 0, 'down')
      if (this.currentPage !== page) {
        this.currentPage = page
      }
    },
    closeHandler (Event) {
      this.close()
    },
    imageLoadHandler () {
      this.isLoaded = true
    },
    gotoPrevImageHandler (Event) {
      if (this.hasPrevImage) {
        this.selectImage(this.currentImage - 1, true)
      }
    },
    gotoNextImageHandler (Event) {
      if (this.hasNextImage) {
        this.selectImage(this.currentImage + 1, true)
      }
    },
    gotoPrevPageHandler () {
      if (this.hasPrevPage) {
        this.currentPage--
      }
    },
    gotoNextPageHandler () {
      if (this.hasNextPage) {
        this.currentPage++
      }
    },
    clickThumbHandler (Event, i) {
      log('clickThumbHandler')
      Event.stopPropagation()
      this.selectImage(i, false)
    },

    // only update carousel, when the window changed for 10px
    changeWindowWidthHandler (Event) {
      let diff = this.lastWindowWidth - Event.detail
      if (diff >= 10 || diff <= -10) {
        this.lastWindowWidth = Event.detail
        this.init()
        this.gotoCurrentPage()
      }
    },
    slideshowOpenHandler (Event) {
      this.open(Event.detail.files, Event.detail.start)
    },
    slideshowCloseHandler (Event) {
      this.close()
    }
  },
  events: {
    'slideshow/open': 'slideshowOpenHandler',
    'slideshow/close': 'slideshowCloseHandler',
    'window/width': 'changeWindowWidthHandler',
    'key/esc': 'closeHandler',
    'key/left': 'gotoPrevImageHandler',
    'key/right': 'gotoNextImageHandler'
  }
}
</script>

<style lang="sass">
.cs-segment-slideshow
  position: fixed
  top: 0
  left: 0
  bottom: 0
  right: 0
  overflow: hidden
  transition: opacity .6s ease-in
  opacity: 0
  +z-index('modal', 1)
  &.is-visible
    opacity: 1
  .canvas
    display: flex
    flex-direction: column
    width: 100%
    height: 100%
    background-color: color('black')
    .menu
      flex-shrink: 1
      display: flex
      flex-direction: row
      align-items: center
      justify-content: space-between
      width: 100%
      padding: m(2) m(2)
      +z-index('modal', 3)
      .caption
        margin: 0
        white-space: nowrap
        overflow: hidden
        text-overflow: none
        color: color('white')
        transition: opacity .4s ease-out
        opacity: 1
        &.fade-enter
          opacity: 0
          transition: opacity .4s ease-in .3s
        &.fade-leave-active
          opacity: 0
          transition: opacity .4s ease-in .3s
    .image
      display: flex
      flex-direction: column
      flex-grow: 1
      padding: 0 m(2) m(2) m(2)
      +z-index('modal', 2)
      .wrapper
        position: relative
        flex-grow: 1
        transition: transform .5s ease-out, opacity .3s ease-out
        // &.slide-left-enter, // moving to right
        &.slide-right-leave-active
          opacity: 0
          transform: translate(10%, 0)
          transition: transform .5s ease-in, opacity .3s ease-in .2s
        // &.slide-left-leave-active, // moving to left
        &.slide-right-enter
          opacity: 0
          transform: translate(-10%, 0)
          transition: transform .5s ease-in, opacity .3s ease-in .2s
        img
          position: absolute
          top: 50%
          bottom: 0
          left: 50%
          right: 0
          max-width: 100%
          max-height: 100%
          transform: translate(-50%,-50%)
      .cs-spinner
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%,-50%)
      .nav-image
        position: absolute
        top: 0
        height: 100%
        width: 40%
        +z-index('modal', 3)
        .cs-button
          height: 100%
          width: 100%
        &.prev
          left: m(2)
          .cs-button
            justify-content: flex-start
        &.next
          right: m(2)
          .cs-button
            justify-content: flex-end
    .thumbs
      flex-shrink: 1
      display: flex
      flex-direction: row
      justify-content: center
      padding: m(2)
      +z-index('modal', 3)
      .sideshow-elem-carousel
        .VueCarousel-wrapper
          .VueCarousel-inner
            .VueCarousel-slide
              .frame
                display: flex
                flex-direction: column
                // width and padding are set by style
                cursor: pointer
              .marker
                height: 3px
                margin-top: 2px
                background-color: transparent
              &.is-current
                .frame
                  cursor: default
                  .marker
                    background-color: color('white')
        .VueCarousel-pagination,
        .VueCarousel-navigation
          display: none !important
      .nav-thumbs
        flex-shrink: 1
        display: flex
        flex-direction: column
        justify-content: center
    &.is-single
      .nav-image
        display: none
      .thumbs
        display: none

+xs
  .cs-segment-slideshow
    .canvas
      .image
        .nav
          display: none
        // .cs-button
      .thumbs
        display: none
</style>
