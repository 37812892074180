<template>
  <div class="cs-base">
    <transition name="fade" mode="out-in">
      <component
        :is="currentTemplate"
        :key="currentKey"
        class="cs-template">
      </component>
    </transition>
    <cs-segment-cookie />
    <cs-segment-modal />
    <cs-segment-slideshow />
    <cs-segment-print />
  </div>
</template>

<script>

/**
 * Base Template is the universal entry point for ALL pages
 * It loades the page-specific template which then again loades the page
 */

// eslint-disable-next-line
import styles from '@/styles/base.sass'
import CsSegmentCookie from '@/components/segments/Cookie'
import CsSegmentModal from '@/components/segments/Modal'
import CsSegmentPrint from '@/components/segments/Print'
import CsSegmentSlideshow from '@/components/segments/Slideshow'
import { favicon } from '@/config/global.json'

export default {
  name: 'cs-default',
  components: {
    CsSegmentCookie,
    CsSegmentModal,
    CsSegmentSlideshow,
    CsSegmentPrint
  },
  computed: {
    currentTemplate () {
      return this.$router.getTemplate()
    },
    currentKey () {
      return 'cs-template-' + this.$router.getBlueprint()
    }
  },
  metaInfo () {
    var meta = [
      { vmid: 'charset', charset: 'utf-8' },
      { vmid: 'http-equiv', 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
      { vmid: 'viewport', name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { vmid: 'author', name: 'author', content: this.$store.getters.author },
      { vmid: 'keywords', name: 'keywords', content: this.$store.getters.keywords },
      { vmid: 'description', name: 'description', content: this.$store.getters.description },
      { vmid: 'og:title', name: 'og:title', content: this.$store.getters.titleComplete },
      { vmid: 'og:description', name: 'og:description', content: this.$store.getters.description },
      { vmid: 'og:url', name: 'og:url', content: fn.currentUrl() },
      { vmid: 'og:type', name: 'og:type', content: this.$store.getters.type },
      { vmid: 'og:locale', name: 'og:locale', content: this.$store.getters.locale },
      { vmid: 'og:image', name: 'og:image', content: this.$store.getters.image },
      { vmid: 'og:image:width', name: 'og:image:width', content: this.$store.getters.imageWidth },
      { vmid: 'og:image:height', name: 'og:image:height', content: this.$store.getters.imageHeight },
      { vmid: 'twitter:card', name: 'twitter:card', content: this.$store.getters.description }
    ]
    var link = []
    if (process.env.NODE_ENV !== 'development' && favicon) {
      if (fn.has(favicon, 'meta')) {
        meta = meta.concat(favicon.meta)
      }
      if (fn.has(favicon, 'link')) {
        link = link.concat(favicon.link)
      }
    }
    return {
      title: this.$store.getters.title,
      titleTemplate: this.$store.getters.titleTemplate,
      meta: meta,
      link: link
    }
  },
  beforeRouteUpdate (to, from, next) {
    this.$meta().refresh()
    next()
  }
}
</script>

<style lang="sass">
.cs-base
  +z-index('base')
  .cs-template 
    padding-top: 0
    padding-bottom: 0
    transition: all .4s cubic-bezier(.55,0,.1,1)
    &.fade-enter-active,
    &.fade-leave-active
      transition: opacity .5s ease
    &.fade-enter,
    &.fade-leave-active
      opacity: 0
</style>
