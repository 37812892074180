<template>
  <component
    :is="node"
    class="cs-button button"
    :class="[iconClass, disabledClass, spinnerClass]"
    @click="clickHandler($event)">
      <slot></slot>
      <cs-spinner v-if="spinner"></cs-spinner>
  </component>
</template>

<script>
export default {
  name: 'cs-button',
  props: {
    icon: {
      type: String,
      default: ''
    },
    node: {
      type: String,
      default: 'button'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    spinner: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iconClass () {
      if (fn.isString(this.icon)) {
        var cssClass = [
          'icon-' + this.icon
        ]
        if (!fn.isArray(this.$slots.default) && !fn.isString(this.$slots.default)) {
          cssClass.push('icon-only')
        }
        return cssClass.join(' ')
      } else {
        return ''
      }
    },
    disabledClass () {
      return this.disabled || this.spinner ? 'is-disabled' : ''
    },
    spinnerClass () {
      return this.spinner ? 'has-spinner' : ''
    }
  },
  methods: {
    clickHandler (Event) {
      if (!this.spinner && !this.disabled) {
        this.$emit('click', Event)
      }
    }
  }
}
</script>

<style lang="sass">
.cs-button
  // styles not here, because other elements (cs-link) can have "button" class
</style>