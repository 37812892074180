<template>
  <div class="cs-box">
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'cs-box'
}
</script>

<style lang="sass">
.cs-box
  padding: col-gap()

+md
  .cs-box
    padding: col-gap('md')

+sm
  .cs-box
    padding: col-gap('sm')

+xs
  .cs-box
    padding: col-gap('xs')
</style>
