<template>
  <component
    :is="component"
    class="cs-col">
      <slot></slot>
  </component>
</template>

<script>

export default {
  name: 'cs-col',
  props: {
    'component': {
      type: String,
      default: 'div'
    }
  }
}
</script>

<style lang="sass">
.cs-row
  >.cs-col
    display: block
    flex-grow: 1
    flex-shrink: 1
    padding: col-gap() / 2
    @for $i from 1 through $colcount
      &.is-#{$i}
        flex: none
        width: percentage($i / $colcount)
      &.is-offset-#{$i}
        margin-left: percentage($i / $colcount)
    @for $i from 1 through 10
      @for $j from 1 through $i
        &.is-#{$j}-from-#{$i}
          flex: none
          width: percentage((10 / $i * $j) / 10)
        &.is-offset-#{$j}-from-#{$i}
          margin-left: percentage((10 / $i * $j) / 10)
  &.no-gap
    >.cs-col
      padding: 0
    &.is-first
      >.cs-col
        padding-top: col-gap()/2
    &.is-last
      >.cs-col
        padding-bottom: col-gap()/2

+md
  .cs-row
    >.cs-col
      padding: col-gap('md') / 2
      @for $i from 1 through $colcount
        &.is-#{$i}-md
          flex: none
          width: percentage($i / $colcount)
        &.is-offset-#{$i}-md
          margin-left: percentage($i / $colcount)
      @for $i from 1 through 10
        @for $j from 1 through $i
          &.is-#{$j}-from-#{$i}-md
            flex: none
            width: percentage((10 / $i * $j) / 10)
          &.is-offset-#{$j}-from-#{$i}-md
            margin-left: percentage((10 / $i * $j) / 10)
      &.is-no-offset-md
        margin-left: 0
    &.no-gap,
    &.no-gap-md
      >.cs-col
        padding: 0
      &.is-first
        >.cs-col
          padding-top: col-gap('md')/2
      &.is-last
        >.cs-col
          padding-bottom: col-gap('md')/2

+sm
  .cs-row
    >.cs-col
      padding: col-gap('sm') / 2
      @for $i from 1 through $colcount
        &.is-#{$i}-sm
          flex: none
          width: percentage($i / $colcount)
        &.is-offset-#{$i}-sm
          margin-left: percentage($i / $colcount)
      @for $i from 1 through 10
        @for $j from 1 through $i
          &.is-#{$j}-from-#{$i}-sm
            flex: none
            width: percentage((10 / $i * $j) / 10)
          &.is-offset-#{$j}-from-#{$i}-sm
            margin-left: percentage((10 / $i * $j) / 10)
      &.is-no-offset-sm
        margin-left: 0
    &.no-gap,
    &.no-gap-sm
      >.cs-col
        padding: 0
      &.is-first
        >.cs-col
          padding-top: col-gap('sm')/2
      &.is-last
        >.cs-col
          padding-bottom: col-gap('sm')/2

+xs
  .cs-row
    >.cs-col
      padding: col-gap('xs') / 2
      &.is-no-offset-xs
        margin-left: 0
    &:not(.is-xs)
      >.cs-col
        margin-left: 0
        width: 100%
      &.no-gap,
      &.no-gap-xs
        >.cs-col
          padding: 0
        &.is-first
          >.cs-col:first-child
            padding-top: col-gap('xs')/2
        &.is-last
          >.cs-col:last-child
            padding-bottom: col-gap('xs')/2
    &.is-xs
      >.cs-col
        @for $i from 1 through $colcount
          &.is-#{$i}-xs
            width: percentage($i / $colcount)
          &.is-offset-#{$i}-xs
            margin-left: percentage($i / $colcount)
        @for $i from 1 through 10
          @for $j from 1 through $i
            &.is-#{$j}-from-#{$i}-xs
              flex: none
              width: percentage((10 / $i * $j) / 10)
            &.is-offset-#{$j}-from-#{$i}-xs
              margin-left: percentage((10 / $i * $j) / 10)
        &.is-no-offset-xs
          margin-left: 0
      &.no-gap,
      &.no-gap-xs
        >.cs-col
          padding: 0
        &.is-first
          >.cs-col
            padding-top: col-gap('xs')/2
        &.is-last
          >.cs-col
            padding-bottom: col-gap('xs')/2
</style>
