<template>
  <component
    v-if="hasContent"
    :is="component"
    :start="attrStart"
    class="cs-list">
      <cs-text 
        v-for="(item, i) in data"
        :key="i"
        component="li"
        :data="item" />
  </component>
</template>

<script>

export default {
  name: 'cs-list',
  components: {},
  props: {
    data: Array,
    component: {
      type: String,
      default: 'ul'
    },
    start: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {}
  },
  computed: {
    attrStart () {
      return (this.component === 'ol' && this.start > 1) ? this.start : null
    },
    hasContent () {
      return (fn.isArray(this.data) && this.data.length > 0)
    },
  }
}
</script>

<style lang="sass">
// .cs-list
</style>