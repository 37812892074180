<template>
  <img class="cs-svg" :src="src" />
</template>

<script>

/**
 * requires vue-svg-loader, which is good for
 * inline and external SVGs and css-background
 * use this module for extern SVGs
 * use loader's build in component with Component = import "@/assets/svg/logo?inline"
 * use background: url('../../assets/svg/logo.svg) for background-svgs
 * @see https://vue-svg-loader.js.org/faq.html#how-to-use-both-inline-and-external-svgs
 */
export default {
  name: 'cs-svg',
  props: {
    name: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      src: null
    }
  },
  mounted () {
    import('@/assets/svg/' + this.name + '.svg')
      .then((response) => {
        this.src = response.default
      })
      .catch((reason) => {
        error('svg ' + name + ' not found')
        this.src = null
      })
  }
}
</script>

<style lang="sass">

  // every color-class or fill is ignored, when svg has fill-attribute
  // (which is intended to have multi-colored svgs)
  .cs-svg
    height: auto
    fill-rule: evenodd
    clip-rule: evenodd
    stroke-linejoin: round
    stroke-miterlimit: 1.41421
    fill: color('grey')
    &.is-primary
      fill: color('primary')
    &.is-secondary
      fill: color('secondary')
    &.is-warning
      fill: color('warning')
    &.is-success
      fill: color('success')
    &.is-white
      fill: color('white')
</style>
