<template>
  <vue-plyr class="cs-video">
    <div
      :data-plyr-provider="video.provider"
      :data-plyr-embed-id="video.videoid">
    </div>
  </vue-plyr>
</template>

<script>

export default {
  name: 'cs-video',
  props: {
    video: {
      type: Object // { videoid, provider }
    }
  }
}
</script>

<style lang="sass">
// .cs-video
</style>
