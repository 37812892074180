<template>
  <cs-segment
    v-if="show"
    :class="{ 'animate': animate}"
    class="cs-segment-cookie">
      <cs-container class="is-fullwidth">
        <cs-row>
          <cs-col class="typo">
            <p v-html="msg"></p>
          </cs-col>
        </cs-row>
        <cs-row>
          <cs-col class="buttons">
            <cs-link :data="$store.state.site.pages.dataprotection">
              <cs-button class="button is-text">{{ $t('cookie.more') }}</cs-button>
            </cs-link>
            <cs-button class="button" @click="decline($event)">{{ $t('cookie.decline') }}</cs-button>
            <cs-button class="button is-primary" @click="ok($event)">{{ $t('cookie.ok') }}</cs-button>
          </cs-col>
        </cs-row>
      </cs-container>
  </cs-segment>
</template>

<script>

export default {
  name: 'cs-segment-cookie',
  props: {},
  data() {
    return {
      show: false,
      animate: false
    }
  },
  computed: {
    showConsentDialog () {
      return this.$store.getters['cookie/showConsentDialog']
    },
    msg () {
      if (this.$analytics.active()) {
        return this.$t('cookie.msg2')
      } else {
        return this.$t('cookie.msg1')
      }
    }
  },
  methods: {
    toggleView (show) {
      if (show) {
        this.show = true
        window.setTimeout(() => {
          this.animate = true
        }, 2000)
      } else {
        this.animate = false
        window.setTimeout(() => {
          this.show = false
        }, 2000)
      }
    },
    decline (Event) {
      this.$store.dispatch('cookie/setConsent', false)
    },
    ok (Event) {
      this.$store.dispatch('cookie/setConsent', true)
      this.$analytics.init()
        .then(() => {
          this.$analytics.track(this.$route.fullPath, '/')
        })
    }
  },
  watch: {
    showConsentDialog: {
      immediate: true,
      handler (show) {
        this.toggleView(show)
      }
    }
  }
}
</script>

<style lang="sass">
.cs-segment-cookie
  position: fixed
  display: flex
  flex-direction: column
  bottom: 0
  left: 0
  right: 0
  width: 100%
  padding: m(2)
  background-color: color('white')
  transform: translateY(100%)
  transition: all .8s ease
  border-top: 1px solid color('primary')
  +z-index('modal', 1)
  .typo
    p
      text-align: center
  .buttons
    display: flex !important
    justify-content: center
    align-items: center
    flex-wrap: wrap
    .cs-button
      margin: m(1)
  &.animate
    transform: none

+xs
  .cs-segment-cookie
    padding: m(2) m(1)
</style>
